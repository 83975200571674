import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/mateuszbialon/Desktop/app-v1/src/layouts/index.js";
import BG from '../components/bg';
import styled from 'styled-components';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Subtitle = makeShortcode("Subtitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title='FAQ' path={props.location.pathname} mdxType="SEO" />
    <BG mdxType="BG" /><div style={{
      maxWidth: '650px',
      margin: '0px auto 10rem auto',
      padding: '0 0.5rem'
    }}>
      <Title mdxType="Title">Frequently Asked Questions</Title>
      <Subtitle mdxType="Subtitle">
Are you able to convert every page into an app?
      </Subtitle>
      <p>{`We can handle every website if it has responsive design (looks good and works on mobile devices through web browser).
I recommend you to download our official demo application and verify how your website will look on mobile screen. Every feature will be working in the same way like on the webpage.`}</p>
      <Subtitle mdxType="Subtitle">
How long it takes to convert my webpage into an app?
      </Subtitle>
      <p>{`The implementation time of the project is respectively:
for Android platform 1-7 days
for iOS platform 3-14 days.
The waiting time is mainly determined by the security checking process on the part of the PlayStore and AppStore management team.`}</p>
      <Subtitle mdxType="Subtitle">
Do you help to create a developer account?
      </Subtitle>
      <p>{`We help you through the entire process, from setting up a developer account to uploading your app to the PlayStore / AppStore.`}</p>
      <Subtitle mdxType="Subtitle">
Do you upload the app to your PlayStore/ AppStore account?
      </Subtitle>
      <p>{`Unfortunately, we cannot upload the app on our account. We help you through with the process of uploading your app on Play Store / AppStore.`}</p>
      <Subtitle mdxType="Subtitle">
What payment methods do you accept?
      </Subtitle>
      <p>{`We accept BLIK, PayPal, USDT or bank transfers. Before the transfer, we send you a testing .apk to install on one of your devices and verify the last look of the service. `}</p>
      <Subtitle mdxType="Subtitle">
More questions?
      </Subtitle>
      <p>{`Contact us at `}<a parentName="p" {...{
          "href": "mailto:contact@web2app.app"
        }}>{`contact@web2app.app`}</a>{`.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      